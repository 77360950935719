body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  font-family: Outfit;
}

h1 {
}

h2 {
}

h3 {
  margin-bottom: 0;
}

#canvas {
  background: #ffe4e4;
  position: absolute;
}

.content {
  padding-top: 20px;
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  margin-bottom: 75px;
}

.phone {
  margin-top: 30px;
}

.wrap {
  max-width: 500px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  background-color: white;
  padding: 0 20px;
}

.wrap.terms {
  text-align: left;
}

header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nav {
  display: flex;
  justify-content: center;
}

.nav ul {
  list-style: none;
  display: flex;
  padding-left: 0;
}

.nav li {
  margin-right: 1rem;
}

.nav a {
  color: black;
  border-bottom: 1px solid black;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 500;
}

.nav .active {
  border-bottom: 0;
}

.nav .loginButton {
  border-bottom: none;
  top: 3px;
  position: relative;
}

/* BUTTONS */

.button {
  padding: 0.6em 1.2em;
  background: #3a273e;
  border-radius: 12px;
  color: white;
  text-decoration: none;
}

.button--large {
  font-size: 18px;
}

/* HOMEPAGE */

.section-features {
}

.section-features__wrap {
  display: flex;
  flex-wrap: wrap;
}

.section-features__wrap > div {
  flex: 1 210px;
  min-width: 0;
}

/* ADMIN PAGE */

.stat {
  background: #f5f5f5;
  border-radius: 12px;
  padding: 10px;
}

.stat_number {
  font-size: 3em;
  line-height: 1em;
}
