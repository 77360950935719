/* Basic -------------------------------- */

._ignoreTouch {
  pointer-events: none;
}
._uppercase {
  text-transform: uppercase;
}
._hideSelect {
  user-select: none;
}
._centerText {
  text-align: center;
}
/* Colors -------------------------------- */

._color-1 {
  background-color: aliceblue;
}
._color-2 {
  background-color: aquamarine;
}
._color-3 {
  background-color: bisque;
}
._color-4 {
  background-color: tomato;
}
/* Space -------------------------------- */

._mr-5 {
  margin-right: 5px;
}
._mb-5 {
  margin-bottom: 5px;
}
._ml-5 {
  margin-left: 5px;
}
._mt-5 {
  margin-top: 5px;
}
._mr-10 {
  margin-right: 10px;
}
._mb-10 {
  margin-bottom: 10px;
}
._ml-10 {
  margin-left: 10px;
}
._mt-10 {
  margin-top: 10px;
}
._mr-15 {
  margin-right: 15px;
}
._mb-15 {
  margin-bottom: 15px;
}
._ml-15 {
  margin-left: 15px;
}
._mt-15 {
  margin-top: 15px;
}
._mr-20 {
  margin-right: 20px;
}
._mb-20 {
  margin-bottom: 20px;
}
._ml-20 {
  margin-left: 20px;
}
._mt-20 {
  margin-top: 20px;
}
/* Flex -------------------------------- */
/* https://codyhouse._co/ds/docs/framework/util-flexbox */

._flex {
  display: flex;
}
._inline-flex {
  display: inline-flex;
}
._flex-wrap {
  flex-wrap: wrap;
}
._flex-nowrap {
  flex-wrap: nowrap;
}
._flex-column {
  flex-direction: column;
}
._flex-column-reverse {
  flex-direction: column-reverse;
}
._flex-row {
  flex-direction: row;
}
._flex-row-reverse {
  flex-direction: row-reverse;
}
._flex-center {
  justify-content: center;
  align-items: center;
}
._flex-one {
  flex: 1;
}
